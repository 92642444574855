<template><v-app><Header /><v-main><v-container fluid>
<v-row>
    <v-col cols="12" md="4">
        <v-img :lazy-src="nophoto" :src="coverbook"></v-img>
    </v-col>
    <v-col cols="12" md="4">
        <v-row><v-col cols="3">{{ $t('Title') }}</v-col><v-col cols="9">{{ title }}</v-col></v-row>
        <v-row><v-col cols="3">{{ $t('Edition') }}</v-col><v-col cols="9">{{ edition }}</v-col></v-row>
        <v-row><v-col cols="3">{{ $t('Author') }}</v-col><v-col cols="9">{{ author }}</v-col></v-row>
        <v-row><v-col cols="3">{{ $t('Publisher') }}</v-col><v-col cols="9">{{ publisher }}</v-col></v-row>
        <v-row><v-col cols="3">{{ $t('Physical Description') }}</v-col><v-col cols="9">{{ deskripsi_fisik }}</v-col></v-row>
        <v-row><v-col cols="3">{{ $t('Availability') }}</v-col><v-col cols="9">{{ ketersediaan }}</v-col></v-row>
        <v-row><v-col cols="3">{{ $t('Language') }}</v-col><v-col cols="9">{{ language }}</v-col></v-row>
    </v-col>
    <v-col cols="12" md="4">
        <v-row><v-col>
        <v-list>
        <v-subheader>F I L E S</v-subheader>
            <v-list-item v-for="(filenya, i) in catalog_files" :key="i"><v-list-item-icon><v-icon>fa-check</v-icon></v-list-item-icon>
            <v-list-item-content>
            <v-list-item-title>
                <a v-if="filenya.url.length > 0" href="#" @click="countReader(filenya, true)">{{ filenya.file_name }}</a>            
                <span v-else>{{ filenya.file_name }}</span>            
            </v-list-item-title>
            <v-list-item-subtitle v-html="filenya.file_ext"></v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        </v-list>
        </v-col></v-row>
    </v-col>
</v-row>

<v-row align="center" justify="center"><v-col cols="12" v-show="!show_form_resume"><v-btn x-large color="success" dark @click="show_form_resume = !show_form_resume"><v-icon class="mr-2">fa-plus</v-icon> {{ $t('New resume') }}</v-btn></v-col></v-row>
<!-- Start resume form -->
<v-row align="center" justify="center" v-show="show_form_resume"><v-col cols="12" md="4">
    <v-card elevation="2" outlined>
    <v-card-title>{{ $t('Resume') }}</v-card-title>
    <v-card-text>
    <v-row><v-col cols="3" md="2"><div class="pt-3">Rating</div></v-col><v-col cols="9" md="10"><v-rating style="float:left;" v-model="rating" label="Rating" background-color="lime darken-2" color="lime darken-3" /></v-col></v-row>
    <v-row><v-col cols="12"><v-textarea solo name="resumetext" :label="$t('Resume')" v-model="resume" /></v-col></v-row>
    </v-card-text>
    <v-card-actions>
    <v-btn @click="saveresume()" class="mr-2" color="success" dark><v-icon class="mr-2">fa-save</v-icon> {{ $t('Save') }}</v-btn>
    <v-btn color="red" dark @click="show_form_resume = !show_form_resume"><v-icon class="mr-2">fa-window-close</v-icon> {{ $t('Cancel') }}</v-btn>
    </v-card-actions>
    </v-card>
</v-col></v-row>
<!-- End resume form -->
    
<!-- Start resume layout -->
<v-row><v-col cols="12">{{ $t('Reader count') }} : {{ jmlPembaca }}</v-col></v-row>

<v-row v-show="resumeTable.length > 0"><v-col cols="12">
<v-card elevation="2" outlined>
        <v-card-title class="justify-center">Resume</v-card-title>
    </v-card>
</v-col></v-row>


<v-progress-linear color="success accent-4" indeterminate height="6" v-show="ajaxResume" />
    
<div v-show="!ajaxResume">
<v-card elevation="2" outlined v-for="(resume, index) in resumeTable" :key="index" :id="index" class="mt-4"><v-card-text>
<v-row>
<v-col cols="12">
    <div style="float:left;" class="text-h6 font-weight-bold blue--text">{{ resume.member.fullname }}</div>
    <div style="float:left;" class="pt-2 ml-6">{{ resume.date_show }}</div>
    <v-rating style="float:left;" v-model="resume.rating" background-color="lime darken-2" color="lime darken-3" readonly class="ml-6" />
</v-col>
</v-row>
<v-row>
<v-col cols="4" md="1"><v-avatar>
      <v-img :lazy-src="nophoto" :src="resume.member.photoUrl" max></v-img>
    </v-avatar>
</v-col>
<v-col cols="8" md="11" v-html="resume.resume_html" />
</v-row>
</v-card-text></v-card>
</div>


<!-- End resume layout -->

<v-pagination v-show="resumeTable.length > 0" v-model="resumepaging.page" :length="resumepaging.length" :total-visible="resumepaging.visible" @input="getresumetable()" />
</v-container></v-main><Footer /></v-app></template>

<script>
import Header from './layout/Header';
import Footer from './layout/Footer';

export default {
    components: { Header, Footer },
    data: () => ({
        nophoto:process.env.VUE_APP_NO_PHOTO,
        coverbook:'',title:'',edition:'',publisher:'',deskripsi_fisik:'',language:'',author:'',ketersediaan:'',
        catalog_files:[],
        rating:5,show_form_resume:false,jmlPembaca:0,resume:'',resumeTable:[],
        resumepaging:{page:1,visible:6,length:0},
    }),
    methods: {
        saveresume() {
            var vue = this;
            if (vue.ajaxProcess) {
                return;
            }
            
            var dateNow = new Date();
            var parameters = {rating: parseInt(vue.rating), resume:vue.resume, catalog_id:vue.$route.params.id, date_now: dateNow.getTime()};
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(parameters),
            };
            
            vue.ajaxProcess = true;
            fetch(process.env.VUE_APP_WEB_API + 'api/add-resume', requestOptions)
            .then(() => {
                vue.show_form_resume = false;
                vue.getresumetable();
            })
            .catch(() => {
                vue.ajaxProcess = false;
                window.location.replace("/logout");
            });
        },
        getresumetable() {
            var vue = this;
            var parameters = {catalog_id: vue.$route.params.id, page:vue.resumepaging.page};
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(parameters),
            };
        
            fetch(process.env.VUE_APP_WEB_API + 'api/resume-1catalog-table', requestOptions)
            .then(async response => {
                var result = await response.json();
                vue.resumeTable = JSON.parse(result.resume_table);
                
                vue.resumeTable.forEach(function (item) {
                    item.member = JSON.parse(item.member);
                });
                
                vue.resumepaging.length = result.length;
                vue.ajaxProcess = false;
            })
            .catch(() => {
                vue.ajaxProcess = false;
                window.location.replace("/logout");
            });
        },
        getCatalogDetail() {
            var vue = this;
            var parameters = {id: vue.$route.params.id};
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(parameters),
            };
            
            fetch(process.env.VUE_APP_WEB_API + 'api/katalog-detail', requestOptions)
            .then(async response => {
                var katalog = await response.json();
                vue.coverbook = katalog.cover_url;
                vue.title = katalog.title;
                vue.edition = katalog.edition;
                vue.publisher = katalog.penerbit;
                vue.deskripsi_fisik = katalog.physical_description;
                vue.language = katalog.language;
                vue.author = katalog.pengarang;
                vue.ketersediaan = katalog.ketersediaan;
                vue.catalog_files = JSON.parse(katalog.catalog_files);
            })
            .catch(() => {
                window.location.replace("/logout");
            });
        },
        countReader(filenya, counted) {
            var vue = this;
            
            var parameters = {catalog_id: vue.$route.params.id, counted:counted};
            var requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(parameters),
            };
            
            fetch(process.env.VUE_APP_WEB_API + 'api/count-catalog-reader', requestOptions)
            .then(async response => {
                var countedd = await response.json();
                vue.jmlPembaca = countedd.jmlPembaca;
            })
            .catch(() => {
                //window.location.replace("/logout");
            });
            
            var parameterAddDownload = {catalogfiles_id: filenya.id, waktu:Date.now()};
            var addDownloadOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(parameterAddDownload),
            };
            fetch(process.env.VUE_APP_WEB_API + 'api/add-history-download', addDownloadOptions);
            
            if (filenya.url.length > 0) {
            console.log(filenya);
                window.open(filenya.url, "_blank");
            }
        }
    },
    mounted() {
        var vue = this;
        vue.getCatalogDetail();
        vue.getresumetable();
    }
  }
</script>
